export default function subscribeChanges(db, callback, error, timeout) {
  const errorTimeout = (timeout ?? 500) * 2;
  db.setMaxListeners(20);
  db.changes({
    live: true,
    since: 'now',
  })
    .on('change', callback)
    .on('error', err => {
      error(err);
      console.log('Reconnect in', errorTimeout / 1000, 'seconds');
      setTimeout(() => subscribeChanges(db, callback, error), errorTimeout);
    });
}
