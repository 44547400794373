import React from 'react';
import {IonIcon} from '@ionic/react';
import {Stepper, Step, StepLabel} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import {chevronBack, chevronForward} from 'ionicons/icons';
import HeaderStepper from '../utils/HeaderStepper';

const useRectangleStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#003B72',
    border: '1px solid #003B72',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#003B72',
    borderColor: '#003B72',
    color: '#fff',
  },
  completed: {
    backgroundColor: '#02A38A',
    borderColor: '#02A38A',
    color: '#fff',
  },
});

function RectangleStepIcon(props) {
  const classes = useRectangleStepIconStyles();
  const {active, completed, icon} = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {icon}
    </div>
  );
}

const StepperHeader = props => {
  const theme = useTheme();

  return (
    <div className="stepper-header">
      <HeaderStepper
        steps={props.steps.length}
        className={'MuiMobileStepper-root'}
        position="static"
        variant="text"
        label={props.steps[props.activeStep]}
        nextButton={
          <Button
            size="small"
            onClick={() => props.handleActiveStep(props.activeStep + 1)}
            disabled={props.activeStep === props.steps.length - 1}>
            {theme.direction === 'rtl' ? (
              <IonIcon
                slot="start"
                color={
                  props.activeStep === props.steps.length - 1
                    ? 'medium'
                    : 'primary'
                }
                icon={chevronBack}
              />
            ) : (
              <IonIcon
                slot="start"
                color={
                  props.activeStep === props.steps.length - 1
                    ? 'medium'
                    : 'primary'
                }
                icon={chevronForward}
              />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => props.handleActiveStep(props.activeStep - 1)}
            disabled={props.activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <IonIcon
                slot="start"
                color={props.activeStep === 0 ? 'medium' : 'primary'}
                icon={chevronForward}
              />
            ) : (
              <IonIcon
                slot="start"
                color={props.activeStep === 0 ? 'medium' : 'primary'}
                icon={chevronBack}
              />
            )}
          </Button>
        }
      />
      <Stepper activeStep={props.activeStep} alternativeLabel>
        {props.steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => props.handleActiveStep(index)}>
              {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
              <StepLabel StepIconComponent={RectangleStepIcon} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperHeader;
