import React, {useEffect, useState, useContext, useRef} from 'react';
import {DatabaseContext} from '../context/DatabaseContext';
import {AuthContext} from '../context/AuthContext';
import {UserContext} from '../context/UserContext';
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  useIonModal,
  IonText,
} from '@ionic/react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import * as Sentry from '@sentry/react';

const GlobalAlertComponent = props => {
  const {t} = useTranslation('link_app');

  const databases = useContext(DatabaseContext);
  const [, setIsAuth] = useContext(AuthContext);

  const [user] = useContext(UserContext);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAlertToast, setShowAlertToast] = useState(false);

  const [ticketInfo, setTicketInfo] = useState({
    id: null,
    errors: [
      {
        status: null,
        place: null,
        alertName: null,
        alertSummary: null,
        station: null,
        connector: null,
      },
    ],
    alertCount: null,
    errorChanged: false,
    firstAlertName: '',
  });

  const alertSound =
    require('../../assets/sounds/control_room_alert.wav').default;
  const audioPlayer = React.useRef();

  const [alertMessages, setAlertMessages] = useState([]);
  const [toastMessages, setToastMessages] = useState('');

  const toast = useRef(null);

  const [places, setPlaces] = useState([]);
  useEffect(() => {
    databases.places
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        const docs = result.rows.filter(row => {
          return !row.id.includes('_design');
        });
        setPlaces(docs);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
  }, [databases.places, props.isPlacesLoading, setIsAuth]);

  const NewAlertModalTable = ({dismiss}) => {
    const alertRows = alertMessages.map(ticketInfo => {
      const place = places.find(place =>
        place.doc?.stations?.some(
          station =>
            station?.id?.toString() ===
              ticketInfo.errors[0].station?.toString() ||
            station?.id?.toString() ===
              ticketInfo.errors[0].station?.toString(),
        ),
      );
      let station = null;
      if (place?.doc?.stations?.length > 0) {
        station = place.doc.stations.find(
          station => station.id.toString() === ticketInfo.errors[0].station,
        );
      }
      return (
        <TableRow>
          <TableCell>{ticketInfo.id ?? 'ID puuttuu'}</TableCell>
          <TableCell>
            {place?.doc?.name ??
              (ticketInfo.errors[0]
                ? ticketInfo.errors[0].place
                : 'Kohteen tiedot puuttuvat')}
          </TableCell>
          <TableCell>
            {station?.name ??
              (ticketInfo.errors[0]
                ? ticketInfo.errors[0].station
                : 'Latausaseman tiedot puuttuvat')}
          </TableCell>
          <TableCell>
            {ticketInfo.errors[0].alertSummary ?? 'Kuvaus puuttuu'}
          </TableCell>
          <TableCell>
            {ticketInfo.errors[0].alertName ?? 'Vikatyyppi puuttuu'}
          </TableCell>
          <TableCell>{ticketInfo.alertCount ?? ''}</TableCell>
        </TableRow>
      );
    });
    return (
      <IonGrid className="ion-margin-none ion-padding">
        <IonRow className="ion-padding-left ion-padding-top ion-padding-right md">
          <IonCol>
            <h2>{t('tickets.new_events')}</h2>
          </IonCol>
        </IonRow>
        <IonRow className="ion-padding">
          <IonCol>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{t('toast.place')}</TableCell>
                    <TableCell>{t('tickets.charging_station')}</TableCell>
                    <TableCell>{t('tickets.description')}</TableCell>
                    <TableCell>{t('toast.error_type')}</TableCell>
                    <TableCell>{t('toast.error_count')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{alertRows}</TableBody>
              </Table>
            </TableContainer>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-padding">
            <IonText>
              Uudet tapahtumat löytyvät Valvomo -näkymän listalta.
            </IonText>
          </IonCol>
          <IonCol className="ion-text-end">
            <IonButton
              className="ion-margin"
              size="medium"
              color="danger"
              onClick={() => {
                setAlertMessages([]);
                dismiss(null, 'cancel');
              }}>
              {t('app.close')} ikkuna
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const [present, dismiss] = useIonModal(NewAlertModalTable, {
    dismiss: data => dismiss(data),
  });

  function openModal() {
    present({
      onWillDismiss: ev => {
        setShowAlertModal(false);
      },
      cssClass: 'alert-modal',
    });
  }

  useEffect(() => {
    if (showAlertModal) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlertModal]);

  useEffect(() => {
    let toastMsg = '';
    if (ticketInfo.id !== null && ticketInfo.id !== undefined) {
      const place = places.find(place =>
        place.doc?.stations.some(
          station =>
            station?.id?.toString() ===
              ticketInfo.errors[0].station?.toString() ||
            station?.id?.toString() ===
              ticketInfo.errors[0].station?.toString(),
        ),
      );
      let station = null;
      if (place?.doc?.stations?.length > 0) {
        station = place.doc.stations.find(
          station => station.id.toString() === ticketInfo.errors[0].station,
        );
      }
      let toastErrors = '';
      ticketInfo.errors.forEach(error => {
        toastErrors += `
        ${t('place.connector')}: ${error.connector ?? ''} <br />
        ${t('toast.error_type')}: ${
          error.alertName ?? 'Vikatyyppi puuttuu'
        }<br />
        `;
      });
      let errorChanged = '';
      if (ticketInfo.errorChanged) {
        errorChanged += `
          <strong>Vikatyyppi muuttunut, ollut alunperin: ${ticketInfo.firstAlertName}</strong>
        `;
      }

      toastMsg = `
      <div>
        <h4>Uusi vikailmoitus työhön (${
          ticketInfo.id ?? 'ID puuttuu'
        }) - Alarm ${ticketInfo.errors[0].status === 'on' ? 'ON' : 'OFF'}</h4>
        <p>${t('toast.place')}: ${
        place?.doc?.name ??
        (ticketInfo.errors[0]
          ? ticketInfo.errors[0].station
          : 'Latausaseman tiedot puuttuvat')
      }<br />
      ${t('tickets.charging_station')}: ${
        station?.name ??
        (ticketInfo.errors[0]
          ? ticketInfo.errors[0].station
          : 'Latausaseman tiedot puuttuvat')
      }<br />
        Kuvaus: ${ticketInfo.errors[0].alertSummary ?? 'Kuvaus puuttuu'}<br />
        Virheilmoitusten määrä: ${ticketInfo.alertCount ?? ''}</p>
        <p>${toastErrors}</p>
        <p>${errorChanged}</p>
      </div>
      `;
    }
    setToastMessages(toastMsg);
    if (showAlertToast) {
      toast.current.message = toastMsg;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketInfo]);

  const onTicketInfoChanged = (ticket, alertType, errors = []) => {
    if (alertType === 'modal') {
      setTicketInfo({
        id: ticket.id,
        errors: [
          {
            status:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].ALARM ?? '',
            place:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].CATEGORY ?? '',
            alertName:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].ALERTNAME ??
              '',
            alertSummary:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].SUMMARY ?? '',
            station:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].STATION_ID ??
              '',
            connector:
              ticket.doc?.errors[ticket.doc?.errors?.length - 1].CONNECTOR ??
              '',
          },
        ],
        alertCount: ticket.doc?.errors?.length,
      });
      setAlertMessages([
        ...alertMessages,
        {
          id: ticket.id,
          errors: [
            {
              status:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].ALARM ?? '',
              place:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].CATEGORY ??
                '',
              alertName:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].ALERTNAME ??
                '',
              alertSummary:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].SUMMARY ??
                '',
              station:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].STATION_ID ??
                '',
              connector:
                ticket.doc?.errors[ticket.doc?.errors?.length - 1].CONNECTOR ??
                '',
            },
          ],
          alertCount: ticket.doc?.errors?.length,
        },
      ]);
    } else if (alertType === 'toast') {
      let toastInfo = {
        id: ticket.id,
        alertCount: ticket.doc?.errors?.length ?? '-',
        errors: [],
        errorChanged: false,
        firstAlertName: '',
      };

      // Check if toast is related to new alert opened in modal, then update alert count if found
      const alertExists = alertMessages.some(alert => alert.id === ticket.id);
      if (alertExists) {
        console.log(
          'update alert count by; ',
          ticket.doc?.errors?.length,
          alert.count,
        );
        setAlertMessages(prevMessages =>
          prevMessages.map(alert =>
            alert.id === ticket.id
              ? {
                  ...alert,
                  alertCount: ticket.doc?.errors?.length ?? alert.count,
                }
              : alert,
          ),
        );
      }

      errors.forEach(error => {
        toastInfo.errors.push({
          status: error.ALARM ?? '-',
          place: error.CATEGORY ?? '-',
          alertName: error.ALERTNAME ?? '-',
          alertSummary: error.SUMMARY ?? '-',
          station: error.STATION_ID ?? '-',
          connector: error.CONNECTOR ?? '-',
        });
        if (error.ALERTNAME !== ticket.doc?.errors[0].ALERTNAME) {
          toastInfo.errorChanged = true;
          toastInfo.firstAlertName = ticket.doc?.errors[0].ALERTNAME;
        }
      });
      props.onTicketUpdatedChanged([ticket.id]);
      setTicketInfo(toastInfo);
    }
  };

  // Check if we got new tickets or alerts in tickets. Play alarm sound if true
  const checkIfNewAlerts = (tickets, newTickets) => {
    if (tickets !== undefined) {
      if (audioPlayer?.current) {
        const oldTicketsMap = new Map(tickets?.map(item => [item.id, item]));

        newTickets.forEach(ticket => {
          try {
            const oldTicket = oldTicketsMap.get(ticket.id);
            if (oldTicket !== undefined) {
              const newErrorsList = ticket.doc?.errors?.slice(
                oldTicket.doc?.errors?.length,
              );

              if (newErrorsList.length > 0) {
                onTicketInfoChanged(ticket, 'toast', newErrorsList);
                audioPlayer.current.play();
                // updateDocumentAlertPlayed(ticket, 'error');
                setShowAlertToast(true);
                return;
              }
            } else {
              onTicketInfoChanged(ticket, 'modal');
              audioPlayer.current.play();
              // updateDocumentAlertPlayed(ticket, 'alert');
              setShowAlertModal(true);
              return;
            }
          } catch (err) {
            console.log(
              "Couldn't find old ticket with id: ",
              ticket.id,
              err.message,
            );
            Sentry.captureException(err);
          }
        });
      }
    }
  };

  // Fetch tickets
  const [tickets, setTickets] = useState(undefined);
  useEffect(() => {
    databases.tickets
      .allDocs({
        include_docs: true,
      })
      .then(result => {
        let docs = result.rows.filter(row => {
          return !row.id.includes('_design') && !row.id.includes('health');
        });
        docs = docs.sort((a, b) => parseInt(b.id) - parseInt(a.id));
        // docs = docs.slice(0, 25);
        let faultedTickets = docs.filter(
          val =>
            !val.doc?.ticket_status || val.doc?.ticket_status === 'faulted',
        );
        let otherTickets = docs.filter(
          val => val.doc?.ticket_status && val.doc?.ticket_status !== 'faulted',
        );
        faultedTickets = faultedTickets.concat(otherTickets);
        checkIfNewAlerts(tickets, faultedTickets);

        setTickets(faultedTickets);
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
        if (err.status === 401) {
          setIsAuth(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databases.tickets, props.isTicketsLoading, setIsAuth]);

  // const updateDocumentAlertPlayed = async (ticket, type) => {
  //   const timestamp = Math.round(new Date() / 1000).toString();
  //   try {
  //     const doc = await databases.tickets.get(ticket.id);

  //     let newAlertPlayed =
  //       ticket.alert_played?.length > 0
  //         ? (
  //             parseInt(
  //               Math.max.apply(
  //                 Math,
  //                 ticket.alert_played?.map(x => x.id),
  //               ),
  //             ) + 1
  //           ).toString()
  //         : '1';

  //     if (!doc.alert_played) {
  //       doc.alert_played = [];
  //     }

  //     doc.alert_played = [
  //       ...doc.alert_played,
  //       {
  //         id: newAlertPlayed,
  //         time: timestamp,
  //         user: user,
  //         app: 'management',
  //         type: type,
  //       },
  //     ];

  //     // Step 3: Put the updated document back into the database
  //     const response = await databases.tickets.put(doc);

  //     console.log('Document updated successfully:', response);
  //   } catch (err) {
  //     console.error('Error updating document:', err);
  //     Sentry.captureException(err);
  //   }
  // };

  return (
    <>
      <IonToast
        isOpen={showAlertToast}
        ref={toast}
        message={toastMessages}
        swipeGesture="horizontal"
        duration={30000}
        layout="stacked"
        cssClass="alert-toast"
        buttons={[
          {
            text: t('app.close'),
            role: 'cancel',
          },
        ]}
        onDidDismiss={e => {
          setShowAlertToast(false);
          setToastMessages('');
        }}></IonToast>
      <audio id="alert-sound" ref={audioPlayer}>
        <source src={alertSound} type="audio/wav"></source>
        Audio file not supported
      </audio>
    </>
  );
};

export default GlobalAlertComponent;
